import { Box, Container, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Logo from './../../assets/images/logo.png';

const listMenu = [
    {
        label: 'คาสิโนสด',
        to: '/casino'
    },
    {
        label: 'เดิมพันกีฬา',
        to: '/sports'
    },
    {
        label: 'เกมส์สล็อต',
        to: '/slot'
    },
    {
        label: 'เกมส์',
        to: '/game'
    },
    {
        label: 'โปรโมชั่น',
        to: '/'
    },
    {
        label: 'สร้างรายได้',
        to: '/'
    },
    {
        label: 'ติดต่อ',
        to: '/'
    },
    
]

const NavMenuMobile = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleOpen = (state) => {
        if(!state || typeof(state) !== 'boolean')
        {
            setIsOpen(!isOpen);
        }

        setIsOpen(state);
    }

    return (
        <>
            <Box width={`100%`} bgcolor={`#3f0101`} className="header-bottom" sx={
                {
                    display: {
                        xs: 'block',
                        sm: 'block',
                        md: 'none',
                        lg: 'none'
                    }
                }
            }>
                <Container>
                    <Box display={`flex`} justifyContent={`space-between`}>
                        <Box display={`flex`} justifyContent={`flex-start`}>
                            <Link to={`/`}>
                                <Box px={2} py={.5} sx={
                                    {
                                        backgroundColor: "transparent",
                                        transition: 'all .3s',
                                        '&:hover': {
                                            backgroundColor: '#1f1f1f'
                                        }
                                    }
                                }>
                                    <Typography color={`white`} fontWeight={100}>
                                        <HomeIcon />
                                    </Typography>
                                </Box>
                            </Link>
                            <Link to={`/`}>
                                <Box px={2} py={.5} sx={
                                    {
                                        backgroundColor: "transparent",
                                        transition: 'all .3s',
                                        '&:hover': {
                                            backgroundColor: '#1f1f1f'
                                        }
                                    }
                                }>
                                    <Typography color={`white`} fontWeight={100}>
                                        ติดต่อเรา
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                        <Box></Box>
                        <Box display={`flex`} justifyContent={`flex-end`}>
                            <Box px={2} py={.5} sx={
                                {
                                    backgroundColor: "transparent",
                                    transition: 'all .3s',
                                    '&:hover': {
                                        backgroundColor: '#1f1f1f'
                                    }
                                }
                            } onClick={() => handleToggleOpen(true)}>
                                <Typography color={`white`} fontWeight={100} display={`flex`} justifyContent={`center`}>
                                    <FormatListBulletedIcon /> เมนู
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box sx={
                {
                    display: {
                        xs: 'block',
                        sm: 'block',
                        md: 'none',
                        lg: 'none'
                    },
                    width: `70%`,
                    height: `100%`,
                    position: 'fixed',
                    top: 0,
                    left: isOpen ? `0%` : `-100%`,
                    color: 'black',
                    backgroundColor: '#5e0000',
                    zIndex: 6,
                    transition: 'left .6s'
                }
            }>
                <Box width={`100%`} py={3}>
                    <Box width={`100%`} display={`flex`} alignItems={`center`} alignContent={`center`}>
                        <img src={Logo} alt="" style={{ width: `auto`, maxWidth: `100%` }} />
                    </Box>
                    <Box display={`flex`} flexDirection={`column`} mt={1}>
                        {
                            listMenu.map((item, index) => {
                                return (
                                    <Link to={item.to} key={index} style={{ marginTop: 1.5 }}>
                                        <Box py={1} sx={
                                            {
                                                background: 'linear-gradient(to right, rgb(67, 4, 4) 0%, rgb(67, 4, 4) 4%, transparent 84%, transparent 100%)'
                                            }
                                        }>
                                            <Typography px={2} fontSize={16}>
                                                {item.label}
                                            </Typography>
                                        </Box>
                                    </Link>
                                )
                            })
                        }
                    </Box>
                </Box>
            </Box>
            <Box sx={
                {
                    display: {
                        xs: isOpen ? 'block' : 'none',
                        sm: isOpen ? 'block' : 'none',
                        md: 'none',
                        lg: 'none'
                    },
                    width: `100%`,
                    height: `100%`,
                    position: 'fixed',
                    top: 0,
                    backgroundColor: 'rgba(0, 0, 0, .5)',
                    zIndex: 5
                }
            } onClick={() => handleToggleOpen(false)}></Box>
        </>
    )
}

export default NavMenuMobile;