import { Typography, Box, Grid } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

import './../../assets/css/homeslide.css';

const HomeGameListSlide = () => {
    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <Typography color={`#dab867`} fontSize={18}>
                        ผลิตภัณฑ์เด่น
                    </Typography>
                </Grid>
                <Grid item xs={12} mt={1}>
                    <Swiper
                        navigation={false}
                        pagination={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        modules={[Navigation, Autoplay, Pagination]}
                        style={
                            {
                                height: `120%`
                            }
                        }
                    >
                        <SwiperSlide>
                            <Box>
                                <Typography color={`white`} fontSize={14} mb={.5} px={1}>
                                    คาสิโนสด
                                </Typography>
                                <Typography color={`#7e7e7e`} fontSize={12} px={1}>
                                    เรามีเกมถ่ายทอดสดสดที่รวบรวมความบันเทิงที่มีมาตรฐานไว้มากมาย
                                </Typography>
                            </Box>
                            <Box>
                                <Typography color={`white`} fontSize={14} px={1}>
                                    เกมสล็อต
                                </Typography>
                                <Typography color={`#7e7e7e`} fontSize={12} mb={.5} px={1}>
                                    เราได้รวบรวมเกมสล็อตชั้นนำไว้ให้คุณได้เลือกสรรและร่วมสนุกมากมาย
                                </Typography>
                            </Box>
                            <Box>
                                <Typography color={`white`} fontSize={14} px={1}>
                                    เกมทายเลข , หวย
                                </Typography>
                                <Typography color={`#7e7e7e`} fontSize={12} mb={.5} px={1}>
                                    เรามีเว็บเกมทายเลขออนไลน์ที่ดีที่สุดในประเทศไทย อย่าง AMBlotto
                                </Typography>
                            </Box>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Box>
                                <Typography color={`white`} fontSize={14} mb={.5} px={1}>
                                    เกมไพ่
                                </Typography>
                                <Typography color={`#7e7e7e`} fontSize={12} px={1}>
                                    เราได้รวบรวมเกมไพ่ชั้นนำไว้ให้คุณได้เลือกสรรและร่วมสนุกมากมาย
                                </Typography>
                            </Box>
                            <Box>
                                <Typography color={`white`} fontSize={14} px={1}>
                                    ป๊อกเด้ง
                                </Typography>
                                <Typography color={`#7e7e7e`} fontSize={12} mb={.5} px={1}>
                                    เราได้รวบรวมเกมสล็อตชั้นนำไว้ให้คุณได้เลือกสรรและร่วมสนุกมากมาย
                                </Typography>
                            </Box>
                            <Box>
                                <Typography color={`white`} fontSize={14} px={1}>
                                    ตีไก่
                                </Typography>
                                <Typography color={`#7e7e7e`} fontSize={12} mb={.5} px={1}>
                                    เรามีเว็บเกมทายเลขออนไลน์ที่ดีที่สุดในประเทศไทย อย่าง AMBlotto
                                </Typography>
                            </Box>
                        </SwiperSlide>
                    </Swiper>
                </Grid>
            </Grid>
        </Box>
    )
}

export default HomeGameListSlide;