import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// ROUTES
import App from './App';
import Home from './components/Home';
import Casino from './components/Game/Casino';
import Slot from './components/Game/Slot';
import Game from './components/Game/Game';
import Lotto from './components/Game/Lotto';
import Sports from './components/Game/Sports';

// CSS
import './assets/css/index.css';

const root = ReactDOM.createRoot(
    document.getElementById('root')
);

const router = createBrowserRouter([
    {
        element: <App />,
        children: [
            {
                path: "/", element: <Home />
            },
            {
                path: "/casino", element: <Casino />
            },
            {
                path: "/slot", element: <Slot />
            },
            {
                path: "/game", element: <Game />
            },
            {
                path: "/lotto", element: <Lotto />
            },
            {
                path: "/sports", element: <Sports />
            }
        ]
    }
]);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
