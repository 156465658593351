import { Outlet } from "react-router-dom";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import { Container } from "@mui/material";

const App = () => {
    return (
        <>
            <Header />
                <Outlet></Outlet>
            <Footer />
        </>
    )
}

export default App;