import { Container, Box, Typography, Grid, Divider } from "@mui/material";

import Card from "./Card";

import SaLogo from "./../../assets/images/products/sa_logo.png";
import DreamLogo from "./../../assets/images/products/dream_logo.png";
import AgLogo from "./../../assets/images/products/ag_logo.png";
import SexyLogo from "./../../assets/images/products/sexy_logo.png";
import AllbetLogo from "./../../assets/images/products/logo_allbet.png";
import EbetLogo from "./../../assets/images/products/logo_ebet.png";
import BigLogo from "./../../assets/images/products/logo_big.png";
import PtLogo from "./../../assets/images/products/pt_logo.png";
import PocketGameSoft from "./../../assets/images/games/games_pg_pocketgamessoft.png";
import GameSlotXo from "./../../assets/images/games/games_slotxo.png";
import GameLiveTwentyTwo from "./../../assets/images/games/games_live22.png";
import DragonSoft from "./../../assets/images/games/games_dragonsoft.png";
import SpadeGaming from "./../../assets/images/games/games_spade_gaming.png";
import Gamatron from "./../../assets/images/games/games_gamatron.png";

const imgStyles = {
    width: '100%',
    filter: 'grayscale(100%)',
    cursor: 'pointer',
    verticalAlign: 'middle'
};
  
const hoverStyles = {
    filter: 'grayscale(0%)',
};

const ListProduct = [
    SaLogo,
    DreamLogo,
    AgLogo,
    SexyLogo,
    AllbetLogo,
    EbetLogo,
    BigLogo,
    PtLogo
]

const Casino = () => {
    return (
        <Container>
            <Box width={`100%`} className="card-header-bg">
                <Typography display={`flex`} justifyContent={`center`} fontSize={24} color={`white`} py={1}>
                    คาสิโนสด
                </Typography>
            </Box>
            <Box sx={
                {
                    backgroundColor: 'rgba(0, 0, 0, 0.309)',
                    textAlign: 'center'
                }
            }>
                <Grid container>
                    <Grid item xs={12} py={2}>
                        <Typography fontSize={20} color={`white`}>
                            คาสิโนออนไลน์ที่ให้บริการ Casino Online ยอดนิยมครบจบที่สุดในไทย
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="line"></Box>
                    </Grid>
                    <Grid item xs={12} my={2}>
                        <Typography fontSize={19} color={`white`} lineHeight={1} px={1}>
                            มีให้ท่านหลายแบรนด์ ทั้ง SA Gaming, Sexy Gaming, ALL Bet , Pretty gaming , eBet , Big Gaming AG Casino และ DG Casino, คาสิโนสดมากมาย เกมสล็อต และ เดิมพันกีฬา รวบรวมผู้ให้บริการระดับโลกมาอยู่ในมือของท่าน แบบ All-In-One ให้ท่านได้สนุก ครบ จบ ในที่เดียว ภายใต้การดูแลบริการตลอด 24 ชั่วโมง
                        </Typography>
                    </Grid>
                    <Grid item xs={12} my={2}>
                        {
                            <Box display={`flex`} flexWrap={`wrap`} alignItems={`center`} justifyContent={`center`}>
                                {
                                    ListProduct.map((item, index) => {
                                        return (
                                            <img key={index} src={item} alt="" style={{ ...imgStyles, transition: 'all .3s', maxWidth: 65, marginLeft: 2 }} onMouseOver={(e) => { e.currentTarget.style.filter = hoverStyles.filter; }} onMouseOut={(e) => { e.currentTarget.style.filter = imgStyles.filter; }} />
                                        )
                                    })
                                }
                            </Box>
                        }
                    </Grid>
                    <Grid item xs={12} mb={2}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Box px={5}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Card image={PocketGameSoft} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Card image={GameSlotXo} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Card image={GameLiveTwentyTwo} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Card image={DragonSoft} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Card image={SpadeGaming} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Card image={Gamatron} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} pt={2} pb={2}>
                        <Box className="line"></Box>
                    </Grid>
                    <Grid item xs={12} mt={2} mb={4}>
                        <Typography fontSize={19} color={`white`} lineHeight={1} px={3}>
                            คุณจะได้เดิมพันกับบริษัทโดยตรง ได้รับราคาเดิมพันดีที่สุด คอมมิชชั่นสูงสุด พนักงานคอลเซ็นเตอร์ให้บริการระดับ VIP ตลอด 24 ชั่วโมง ฝากไม่มีขั้นต่ำ ถอนได้วันละ 2.5 ล้านบาท มั่นคง ปลอดภัย 100%
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default Casino;