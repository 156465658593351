import { Container, Grid, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import TwentyFourHours from "./../../assets/images/24-hours-icon.png";
import ChatIcon from '@mui/icons-material/Chat';
import { FaLine } from "react-icons/fa";

const HomeHelp = () => {
    return (
        <Container sx={{ mb: 3 }}>
            <Grid container columnSpacing={4} rowSpacing={2}>
                <Grid item xs={12} mt={1} mb={3}>
                    <Box className="line"></Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography color={`#dab867`} fontSize={18}>
                                ขั้นตอนการสมัครสมาชิก
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color={`white`} fontSize={13}>
                                1. สมัครสมาชิกง่ายๆ เพียง 1 นาที
                            </Typography>
                            <Typography color={`white`} fontSize={13}>
                                2. ทำรายการอัตโนมัติ ปรับยอดภายใน 30 วินาที
                            </Typography>
                            <Typography color={`white`} fontSize={13}>
                                3. ร่วมสนุกได้เลย เข้าเล่นเกมส์ได้ทันที
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography color={`#dab867`} fontSize={18}>
                                ศูนย์ข้อมูล
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Link to={`/`}>
                                <Typography display={`flex`} sx={
                                    {
                                        justifyContent: {
                                            xs: 'center',
                                            sm: 'flex-end',
                                            md: 'flex-end',
                                            lg: 'flex-end'
                                        }
                                    }
                                }>
                                    คำถามที่พบบ่อย {`>>`}
                                </Typography>
                            </Link>
                            <Link to={`/`}>
                                <Typography display={`flex`} sx={
                                    {
                                        justifyContent: {
                                            xs: 'center',
                                            sm: 'flex-end',
                                            md: 'flex-end',
                                            lg: 'flex-end'
                                        }
                                    }
                                }>
                                    วิธีการฝากเงิน? {`>>`}
                                </Typography>
                            </Link>
                            <Link to={`/`}>
                                <Typography display={`flex`} sx={
                                    {
                                        justifyContent: {
                                            xs: 'center',
                                            sm: 'flex-end',
                                            md: 'flex-end',
                                            lg: 'flex-end'
                                        }
                                    }
                                }>
                                    วิธีการถอนเงิน? {`>>`}
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box display={`flex`}>
                                <img src={TwentyFourHours} alt="" style={{ width: `100%`, maxWidth: `30px` }} />
                                <Typography color={`#dab867`} fontSize={18}>
                                    &nbsp;ติดต่อเรา
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container mt={2} spacing={1}>
                                <Grid item xs={3}>
                                    <Box display={`flex`} justifyContent={`flex-end`} alignItems={`center`} alignContent={`center`}>
                                        <ChatIcon style={{ fontSize: 40, color: 'white' }}/>
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography color={`white`} lineHeight={1}>
                                        แชทสด
                                    </Typography>
                                    <Typography color={`white`} lineHeight={1}>
                                        สอบถามได้ทันที
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box display={`flex`} justifyContent={`flex-end`} alignItems={`center`} alignContent={`center`}>
                                        <FaLine style={{ fontSize: 40, color: 'white' }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography color={`white`} lineHeight={1}>
                                        LINE ติดต่อได้ 24 ชม.
                                    </Typography>
                                    <Typography color={`white`} lineHeight={1}>
                                        @BETWON
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default HomeHelp;