import { Box, Grid, Typography } from "@mui/material";
import CasioImage from "./../../assets/images/cs-ab-5.png";

const HomeGameCasino = () => {
    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <Typography color={`#dab867`} fontSize={18}>
                        คาสิโนสด
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={
                            {
                                maxWidth: {
                                    xs: `100%`,
                                    sm: `100%`,
                                    md: `100%`,
                                    lg: `300px`
                                }
                            }
                        }
                    >
                        <img src={CasioImage} alt="" style={{ width: `100%` }} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default HomeGameCasino;