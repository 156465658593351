import { Box, Grid, Typography, Divider } from "@mui/material";
import ProgressBar from "./ProgressBar";

const HomeDepositWithdraw = () => {
    return (
        <Box>
            <Grid container>
                <Grid item xs={12} mb={1.5}>
                    <Typography color={`#dab867`} fontSize={18}>
                        การให้บริการฝาก-ถอน
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box mb={1.5}>
                        <table cellPadding="0" cellSpacing="0" style={{ width: `100%` }}>
                            <tbody>
                                <tr>
                                    <td>
                                        <Typography fontSize={18} color={`white`}>
                                            การฝากเงิน
                                        </Typography>
                                    </td>
                                    <td rowSpan="2" style={{ verticalAlign: 'bottom', width: '60px' }}>
                                        <Box display={`flex`} justifyContent={`flex-end`}>
                                            <Typography fontSize={34} display={`flex`} flexDirection={`column-reverse`} color={`white`} lineHeight={1.0}>
                                                1
                                            </Typography>
                                            <Typography fontSize={14} display={`flex`} flexDirection={`column-reverse`} color={`#7e7e7e`}>
                                                &nbsp;นาที
                                            </Typography>
                                        </Box>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography fontSize={12} color={`#7e7e7e`}>
                                            เวลาเฉลี่ย
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <ProgressBar value={1} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>
                    <Box mb={1.5}>
                        <table cellPadding="0" cellSpacing="0" style={{ width: `100%` }}>
                            <tbody>
                                <tr>
                                    <td>
                                        <Typography fontSize={18} color={`white`}>
                                            การถอนเงิน
                                        </Typography>
                                    </td>
                                    <td rowSpan="2" style={{ verticalAlign: 'bottom', width: '60px' }}>
                                        <Box display={`flex`} justifyContent={`flex-end`}>
                                            <Typography fontSize={34} display={`flex`} flexDirection={`column-reverse`} color={`white`} lineHeight={1.0}>
                                                3
                                            </Typography>
                                            <Typography fontSize={14} display={`flex`} flexDirection={`column-reverse`} color={`#7e7e7e`}>
                                                &nbsp;นาที
                                            </Typography>
                                        </Box>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography fontSize={12} color={`#7e7e7e`}>
                                            เวลาเฉลี่ย
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <ProgressBar value={3} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} mt={1}>
                    <Typography color={`white`} fontSize={11}>
                        การฝาก-ถอนอาจล่าช้าในช่วงเวลาธนาคารออฟไลน์ กรุณาตรวจสอบช่วงเวลาธนาคารออฟไลน์ได้ ที่นี่
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default HomeDepositWithdraw;