import { Container, Box, Typography, Grid, Divider } from "@mui/material";
import CardFlip from "./CardFlip";

import LottoImage from './../../assets/images/lotto.png';

const Lotto = () => {
    return (
        <Container>
            <Box width={`100%`} className="card-header-bg">
                <Typography display={`flex`} justifyContent={`center`} fontSize={24} color={`white`} py={1}>
                    หวยออนไลน์ที่ดีที่สุด
                </Typography>
            </Box>
            <Box sx={
                {
                    backgroundColor: 'rgba(0, 0, 0, 0.309)',
                    textAlign: 'center'
                }
            }>
                <Grid container>
                    <Grid item xs={12} py={2}>
                        <Typography fontSize={20} color={`white`}>
                            แทงหวยออนไลน์ ซื้อหวยออนไลน์ ผ่านเว็บหวยที่ดีที่สุด อัตราจ่ายสูง!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="line"></Box>
                    </Grid>
                    <Grid item xs={12} my={2}>
                        <Typography fontSize={19} color={`white`} lineHeight={1} px={1}>
                            หวยรัฐบาล สลากกินแบ่งรัฐบาล ซื้อหวยใต้ดิน ผลหวยรัฐบาลจะออกผลทุกๆวันที่1กับ16ของทุกเดือนอาจมีการเลื่อนวันออกไปตามวันสำคัญ เปิดรับหวยแทงออนไลน์ เปิดล่วงหน้า5วัน คอหวยทุกท่านสามารถเดิมพันล่วงหน้าได้ ระบบปิดรับแทงเวลา 15:20 น.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mb={2}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Box px={5}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <CardFlip image={LottoImage} />
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} pt={2} pb={2}>
                        <Box className="line"></Box>
                    </Grid>
                    <Grid item xs={12} mt={2} mb={4}>
                        <Typography fontSize={19} color={`white`} lineHeight={1} px={3}>
                            หวยลาว ຫວຍລາວ สลากพัฒนา ออกผลทุกวันจันทร์กับวันพฤหัสบดี ผลหวยลาวจะออกเวลา 20.30 น.ตามเวลาไทย ปิดรับแทงหวย 20:00 น. มีเปิดรับ4ตัวบนจ่าย7,000บาท ผลออกจากสปป.ลาวโดยตรง
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default Lotto;