import { Typography } from "@mui/material";
import Marquee from "react-fast-marquee";

const TextMarquee = ({ text }) => {
    return (
        <Marquee gradient gradientColor="#3f0101" gradientWidth={15} speed={40}>
            <Typography color={`#fcd500`} fontSize={10} py={1}>
                {text}
            </Typography>
        </Marquee>
    )
}

export default TextMarquee;