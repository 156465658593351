import HomeSlide from "./HomeSlide";
import HomeDetail from "./HomeDetail";
import HomeGame from "./HomeGame";
import HomeHelp from "./HomeHelp";

const HomeComponent = () => {
    return (
        <>
            <HomeSlide />
            <HomeDetail />
            <HomeGame />
            <HomeHelp />
        </>
    )
}

export default HomeComponent;