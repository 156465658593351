import { Grid, Box, Typography } from "@mui/material";
import Line from "./../../assets/images/line-btn.png";
import { Link } from "react-router-dom";

import one from "./../../assets/images/products/22_logo.png";
import two from "./../../assets/images/products/ab_logo.png";
import three from "./../../assets/images/products/ag_logo.png";
import four from "./../../assets/images/products/amb-logo-full.png";
import five from "./../../assets/images/products/ambpoker_logo.png";
import six from "./../../assets/images/products/dream_logo.png";
import seven from "./../../assets/images/products/gmt_logo.png";
import eight from "./../../assets/images/products/logo_allbet.png";
import nine from "./../../assets/images/products/logo_big.png";
import ten from "./../../assets/images/products/pg_logo.png";
import oneone from "./../../assets/images/products/pt_logo.png";
import onetwo from "./../../assets/images/products/pt_logo.png";
import onethree from "./../../assets/images/products/sa_logo.png";
import onefour from "./../../assets/images/products/sexy_logo.png";
import onefive from "./../../assets/images/products/sp_logo.png";
import onesix from "./../../assets/images/products/xo_logo.png";

const imgStyles = {
    width: '100%',
    filter: 'grayscale(100%)',
    cursor: 'pointer',
    verticalAlign: 'middle'
};
  
const hoverStyles = {
    filter: 'grayscale(0%)',
};

const ListProduct = [
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    oneone,
    onetwo,
    onethree,
    onefour,
    onefive,
    onesix
]

const FooterProduct = () => {
    return (
        <Grid container>
            <Grid item xs={4}>
                <Typography color={`white`} fontSize={12}>
                    ติดตามเราได้ที่
                </Typography>
                <Link to={`https://lin.ee/BMyvJ0g`} target="_blank">
                    <img src={Line} alt="" style={{ ...imgStyles, transition: 'all .3s', maxWidth: 150, }} onMouseOver={(e) => { e.currentTarget.style.filter = hoverStyles.filter; }} onMouseOut={(e) => { e.currentTarget.style.filter = imgStyles.filter; }} />
                </Link>
            </Grid>
            <Grid item xs={8}>
                <Typography color={`white`} fontSize={12}>
                    ผู้ให้บริการหลัก
                </Typography>
                {
                    <Box display={`flex`} flexWrap={`wrap`} alignItems={`center`}>
                        {
                            ListProduct.map((item, index) => {
                                return (
                                    <img key={index} src={item} alt="" style={{ ...imgStyles, transition: 'all .3s', maxWidth: 65, marginLeft: 2 }} onMouseOver={(e) => { e.currentTarget.style.filter = hoverStyles.filter; }} onMouseOut={(e) => { e.currentTarget.style.filter = imgStyles.filter; }} />
                                )
                            })
                        }
                    </Box>
                }
            </Grid>
            <Grid item xs={12}>
                <Box className="line-dotted"></Box>
            </Grid>
        </Grid>
    )
}

export default FooterProduct;