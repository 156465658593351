import { Box } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
// import 'swiper/css/navigation';

import './../../assets/css/homeslide.css';

import PosterImage from "./../../assets/images/alide-min.jpg";

const HomeSlide = () => {
    return (
        <Box width={`100%`} sx={
            {
                overflow: 'hidden'
            }
        }>
            <Swiper
                navigation={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                modules={[Navigation, Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img src={PosterImage} alt="" style={{ width: `100%`, maxWidth: `100%` }} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={PosterImage} alt="" style={{ width: `100%`, maxWidth: `100%` }} />
                </SwiperSlide>
            </Swiper> 
        </Box>
    )
}

export default HomeSlide;