import { Box, Container } from "@mui/material";
import TextMarquee from "./TextMarquee";

const HeaderTextMarquee = () => {
    return (
        <Box width={`100%`} bgcolor={`#3f0101`}>
            <Container>
                <TextMarquee text={`ออนไลน์เปิดโลกกว้างให้คุณได้เลือกสิ่งที่ดีที่สุดของเกมออนไลน์จากประสบการณ์ที่การันตีได้มากว่า 10 ปี! ความหลากหลายของ เกมส์คาสิโนออนไลน์ ราคาต่อรองที่ดีที่สุดในหน้ากีฬา และโบนัสเงินฝากที่ให้มากที่สุด ทุกสิ่งเป็นเรื่องง่ายที่นี่ BETWON เอเชีย เว็บไซต์ที่ใครๆก็รู้จัก เว็บไซต์อันเป็นที่ชื่นชอบของวงการคนรักคาสิโน....`} />
            </Container>
        </Box>
    )
}

export default HeaderTextMarquee;