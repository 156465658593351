import { Container, Grid, Box } from "@mui/material";
import HomeGameListSlide from "./HomeGameListSlide";
import HomeGameCasino from "./HomeGameCasino";
import HomeDepositWithdraw from "./HomeDepositWithdraw";

const HomeGame = () => {
    return (
        <Container>
            <Grid container>
                <Grid item xs={12} my={2}>
                    <Box className="line"></Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <HomeGameListSlide />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <HomeGameCasino />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <HomeDepositWithdraw />
                </Grid>
            </Grid>
        </Container>
    )
}

export default HomeGame;