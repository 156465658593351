import { Box, Container, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

const listMenu = [
    {
        label: <HomeIcon />,
        to: '/'
    },
    {
        label: 'คาสิโนออนไลน์',
        to: '/casino'
    },
    {
        label: 'เกมส์สล็อต',
        to: '/slot'
    },
    {
        label: 'เกมส์',
        to: '/game'
    },
    {
        label: 'ล็อตเตอรี่',
        to: '/lotto'
    },
    {
        label: 'กีฬา',
        to: '/sports'
    }
]

const NavMenuDesktop = () => {
    const { pathname } = useLocation();

    return (
        <Box width={`100%`} bgcolor={`#3f0101`} className="header-bottom" sx={
            {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'block',
                    lg: 'block'
                }
            }
        }>
            <Container>
                <Box display={`flex`} justifyContent={`space-between`}>
                    <Box display={`flex`}>
                        {
                            listMenu.map((item, index) => {
                                return (
                                    <Link to={item.to} key={index}>
                                        <Box px={3} py={1} sx={
                                            {
                                                backgroundColor: pathname === item.to ? "#1f1f1f" : "transparent",
                                                transition: 'all .3s',
                                                '&:hover': {
                                                    backgroundColor: '#1f1f1f'
                                                }
                                            }
                                        }>
                                            <Typography color={`white`} fontWeight={100}>
                                                {item.label}
                                            </Typography>
                                        </Box>
                                    </Link>
                                )
                            })
                        }
                    </Box>
                    <Box>
                        <Box display={`flex`}>
                            <Link to={`/`}>
                                <Box px={3} py={1} sx={
                                    {
                                        backgroundColor: "transparent",
                                        transition: 'all .3s',
                                        '&:hover': {
                                            backgroundColor: '#1f1f1f'
                                        }
                                    }
                                }>
                                    <Typography color={`white`} fontWeight={100} display={`flex`} justifyContent={`center`}>
                                        <CardGiftcardIcon />&nbsp;โปรโมชั่น
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default NavMenuDesktop;