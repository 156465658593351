import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import NavMenuDesktop from "./NavMenuDesktop";
import NavMenuMobile from "./NavMenuMobile";
import HeaderTextMarquee from "./HeaderTextMarquee";

import './../../assets/css/header.css';
import Logo from './../../assets/images/logo.png';

import { Box, Container, Grid, Typography, Divider, Button } from "@mui/material";

// ICONS
import { FaLine } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";
import { IoMegaphone } from "react-icons/io5";

const Header = () => {
    const [currentDateTime, setCurrentDateTime] = useState("");

    useEffect(() => {
        updateDateTime();
    }, []);

    const updateDateTime = () => {
        const date = new Date();

        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");
        const timezoneOffsetHours = 7;
        const timezoneString = `(GMT+${timezoneOffsetHours})`;
        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${timezoneString}`;

        setCurrentDateTime(formattedDate);
        setTimeout(updateDateTime, 1000);
    }

    return (
        <>
            <Box width={`100%`} bgcolor={`black`}>
                <Container>
                    <Typography fontSize={12} display={`flex`} justifyContent={`flex-end`} color={`#cfccc8`} alignItems={`center`}>
                        {currentDateTime}
                        &nbsp;
                        |
                        &nbsp;
                        <Link to="https://lin.ee/BMyvJ0g" target="_blank" style={{ display: 'flex', alignItems: 'center' }}>
                            <FaLine />&nbsp;LINE: @BETWON
                        </Link>
                        &nbsp;
                        |
                        &nbsp;
                        <FaQuestionCircle />&nbsp;FAQ
                    </Typography>
                </Container>
            </Box>
            <Box width={`100%`} bgcolor={`red`} className="header-top">
                <Container>
                    <Grid container p={2}>
                        <Grid item xs={6}>
                            <Box width={`100%`} height={`100%`} display={`flex`} alignItems={`center`} alignContent={`center`}>
                                <img src={Logo} alt="" style={{ width: `100%`, maxWidth: '100px' }} />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box display={`flex`} justifyContent={`flex-end`} width={`100%`}>
                                <Typography fontSize={13} display={`flex`} justifyContent={`flex-end`} color={`#cfccc8`} alignItems={`center`}>
                                    <Link to="#" target="_blank" style={{ display: 'flex', alignItems: 'center' }}>
                                        ห้องแชทสด&nbsp;<FaLine />
                                    </Link>
                                </Typography>
                                <Divider orientation="vertical" flexItem color={`#cfccc8`} sx={{ mx: 1, my: .2 }} />
                                <Typography fontSize={13} display={`flex`} justifyContent={`flex-end`} color={`#cfccc8`} alignItems={`center`}>
                                    <IoMegaphone />
                                </Typography>
                                <Divider orientation="vertical" flexItem color={`#cfccc8`} sx={{ mx: 1, my: .2 }} />
                            </Box>
                            <Box display={`flex`} justifyContent={`flex-end`} width={`100%`} mt={1}>
                                <Grid container spacing={.5}>
                                    <Grid item xs={12}>
                                        <Box width={`100%`} display={`flex`} justifyContent={`flex-end`}>
                                            <Button size="small" sx={
                                                {
                                                    borderRadius: 0,
                                                    maxWidth: 146,
                                                    mx: .25,
                                                    backgroundColor: '#fcd500',
                                                    color: '#412500',
                                                    '&:hover': {
                                                        backgroundColor: '#c9a900',
                                                        color: '#412500',
                                                    }
                                                }
                                            } fullWidth onClick={() => {
                                                const link = document.createElement('a');
                                                link.target = "_blank";
                                                link.href = "https://wallet.betwon.com";
                                                link.click();
                                            }}>
                                                เข้าสู่ระบบ
                                            </Button>
                                            <Button size="small" sx={
                                                {
                                                    borderRadius: 0,
                                                    maxWidth: 150,
                                                    mx: .25,
                                                    backgroundColor: '#17a5bc',
                                                    color: '#ffffff',
                                                    '&:hover': {
                                                        backgroundColor: '#107889',
                                                        color: '#ffffff',
                                                    }
                                                }
                                            } fullWidth onClick={() => {
                                                const link = document.createElement('a');
                                                link.target = "_blank";
                                                link.href = "https://wallet.betwon.com/register";
                                                link.click();
                                            }}>
                                                สมัครสมาชิก
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box display={`flex`} justifyContent={`flex-end`} width={`100%`} mt={1}>
                                <Typography fontSize={11} display={`flex`} justifyContent={`flex-end`} color={`#cfccc8`} alignItems={`center`}>
                                    ลืมรหัสผ่าน?
                                    &nbsp;
                                    <Link to="#" target="_blank" style={{ display: 'flex', alignItems: 'center' }}>
                                        <FaLine />&nbsp;ติดต่อเจ้าหน้าที่ลูกค้าสัมพันธ์ที่นี่
                                    </Link>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <NavMenuDesktop />
            <NavMenuMobile />
            <HeaderTextMarquee />
        </>
    )
}

export default Header;