import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#1f1f1f',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#007bff',
    },
}));

const ProgressBar = ({ value }) => {
    return (
        <BorderLinearProgress variant="determinate" value={value || 100} />
    )
}

export default ProgressBar;