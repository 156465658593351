import { Box, Button, Typography } from "@mui/material";

const Card = ({ image, login, register }) => {
    return (
        <>
            <img src={image} alt="" style={{ width: `100%` }} />
            <Box className="card-bg" sx={
                {
                    mt: -1,
                }
            } display={`flex`} justifyContent={`space-between`}>
                <Box display={`flex`} justifyContent={`flex-start`} ml={2} py={1.5}>
                    <Button sx={
                        {
                            color: 'black',
                            background: '#fcd500',
                            borderRadius: 0,
                            padding: '2px 20px',
                            borderBottom: '4px solid #ccad00',
                            '&:hover': {
                                color: 'white',
                                background: '#ff0000',
                                borderRadius: 0,
                                padding: '2px 20px',
                                borderBottom: '4px solid #c10000',
                            }
                        }
                    } onClick={() => {
                        const link = document.createElement('a');
                        link.target = "_blank";
                        link.href = "https://wallet.betwon.com";
                        link.click();
                    }}>
                        <Typography fontWeight={`bold`}>
                            เข้าเล่น
                        </Typography>
                    </Button>
                </Box>
                <Box></Box>
                <Box display={`flex`} justifyContent={`flex-start`} mr={2} py={1.5}>
                    <Button sx={
                        {
                            color: 'white',
                            background: '#ff0000',
                            borderRadius: 0,
                            padding: '2px 20px',
                            borderBottom: '4px solid #c10000',
                            '&:hover': {
                                background: '#4c0000',
                                borderBottom: '4px solid #640000',
                            }
                        }
                    } onClick={() => {
                        const link = document.createElement('a');
                        link.target = "_blank";
                        link.href = "https://wallet.betwon.com/register";
                        link.click();
                    }}>
                        <Typography fontWeight={`bold`}>
                            สมัครสมาชิก
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default Card;